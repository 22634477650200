<template>
  <div class="background-uploader">
    <div class="background-uploader-content">
      <div class="files-background">
        <file-upload
          :accept="
            acceptMore
              ? acceptMore
              : '.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx'
          "
          input-id="eventFiles"
          class="file-uploader"
          post-action="/upload/post"
          :multiple="multiple"
          :drop="drop"
          :drop-directory="dropDirectory"
          v-model="newFile"
          ref="fileUploaderCalendar"
          name="eventFiles"
        >
          <div class="attach-file" v-if="!newFile.length && !editAttachedFiles.length">
            Прикрепить файл
          </div>
        </file-upload>
        <div class="files-info-attached" v-for="(file, index) in editAttachedFiles" :key="file.id">
          <IcoMoonWrap
            :name="setExtensionIconOld(file)['name']"
            :fontSize="18"
            :pathCount="setExtensionIconOld(file)['path']"
          />
          <a target="_blank" :href="file.link" class="files-info-name">
            {{ cutterText(file.label) }}
          </a>
          <div class="files-info-delete" @click="editAttachedFiles.splice(index, 1)">
            <i class="icon-cross"></i>
          </div>
        </div>
        <div class="files-info" v-for="(file, index) in newFile" :key="index">
          <IcoMoonWrap
            :name="setExtensionIcon(file)['name']"
            :fontSize="18"
            :pathCount="setExtensionIcon(file)['path']"
          />
          <div class="files-info-name">
            {{ cutterText(file.name) }}
          </div>
          <div class="files-info-delete" @click="newFile.splice(index, 1)">
            <i class="icon-cross"></i>
          </div>
        </div>
        <label
          class="upload-more"
          v-if="newFile.length || editAttachedFiles.length"
          for="eventFiles"
        >
          <i class="icon-download"></i>
          <span>Добавить еще</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap";

const FileUpload = () => import("vue-upload-component");

export default {
  name: "FileUploaderCalendar",
  mixins: [],
  props: [
    "multiple",
    "drop",
    "dropDirectory",
    "fileDrop",
    "deep",
    "lengthText",
    "showOnlyFiles",
    "editingFiles",
    "attachedFiles",
    "acceptMore"
  ],
  components: { IcoMoonWrap, FileUpload },
  data() {
    return {
      newFile: [],
      editAttachedFiles: []
    };
  },
  mounted() {
    let self = this;
    setTimeout(() => {
      if (this.editingFiles) {
        this.editFile = this.editingFiles;
      }
    }, 0);
    this.$root.$on("getFilesFromUploader", function () {
      self.gettingAddFiles();
    });
    this.$root.$on("getEditFiles", function () {
      self.gettingEditFiles();
    });
    this.editAttachedFiles = JSON.parse(JSON.stringify(this.attachedFiles));
  },
  methods: {
    setExtensionIconOld(file) {
      switch (file.extension) {
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return { name: "doc", path: 7 };
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return { name: "ppt", path: 7 };
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return { name: "xls", path: 7 };
        case "application/pdf":
          return { name: "pdf", path: 7 };
        case "image/jpeg":
        case "image/jpg":
        case "image/png":
          return { name: "picture", path: 3 };
        case "video/mp4":
        case "audio/mpeg":
        case "video/quicktime":
        case "video/3gpp":
        case "video/x-ms-wmv":
        case "video/mpeg":
        case "video/x-flv":
        case "video/x-msvideo":
          return { name: "video-player", path: 11 };
        default:
          break;
      }
    },
    setExtensionIcon(file) {
      switch (file.type) {
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return { name: "doc", path: 7 };
        case "application/vnd.ms-powerpoint":
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return { name: "ppt", path: 7 };
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return { name: "xls", path: 7 };
        case "application/pdf":
          return { name: "pdf", path: 7 };
        case "image/jpeg":
        case "image/jpg":
        case "image/png":
          return { name: "picture", path: 3 };
        default:
          break;
      }
    },
    sizeFloor(size) {
      let tb = 1099511627776;
      let gb = 1073741824;
      let mb = 1048576;
      let kb = 1024;
      if (size >= tb) {
        return Math.floor(size / tb) + "tb";
      } else if (size >= gb && size < tb) {
        return Math.floor(size / gb) + "gb";
      } else if (size >= mb && size < gb) {
        return Math.floor(size / mb) + "mb";
      } else if (size >= kb && size < mb) {
        return Math.floor(size / kb) + "kb";
      } else {
        return size + "b";
      }
    },
    cutterText(text) {
      const extension = text.split(".");
      if (text.length <= this.lengthText) {
        return text;
      } else return text.substr(0, this.lengthText) + "..." + extension[extension.length - 1];
    },
    gettingAddFiles() {
      this.$root.$emit("gettingFiles", this.files);
    },
    gettingEditFiles() {
      this.$root.$emit("gettingEditFiles", this.editFile, this.files);
    }
  },
  computed: {},
  watch: {
    editAttachedFiles() {
      this.$emit("returnAllFiles", this.editAttachedFiles, this.newFile);
    },
    newFile() {
      this.$emit("returnAllFiles", this.editAttachedFiles, this.newFile);
    }
  },
  validations: {},
  directives: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.background-uploader {
  width: 100%;
  position: relative;
  height: auto;
  overflow-y: auto;
  .uploader-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    i {
      font-size: 20px;
      color: $icon-subdued;
      margin-right: 8px;
    }

    .uploader-label {
      font-size: 14px;
      line-height: 20px;
      color: $text-subdued;
      font-weight: 400;
    }

    .uploader-clear {
      margin-left: auto;
      color: $text-default;
      padding: 4px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;

      cursor: pointer;

      &:hover {
        background: $action-secondary-hovered;
        border-radius: 4px;
      }
    }
  }

  &-content {
    display: flex;
    min-height: 23px;
    align-items: center;

    .icon-paper-clip {
      margin-right: 14px;
      font-size: 20px;
      color: $color-text-secondary;
    }
  }
  .files-background {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 30px;

    .file-uploader {
      color: $color-text-primary-2;

      &:hover {
        color: $color-button-hover;
      }

      &:active {
        color: $color-button-click;
      }

      /deep/ {
        img {
          margin-top: 14px;
        }
        .attach-file {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          transition: color 0.3s ease;
        }
        label {
          cursor: pointer;
        }
      }
    }

    .files-info-attached {
      display: flex;
      align-items: center;
      margin-right: 7px;
      margin-bottom: 7px;
      padding-left: 4px;
      padding-right: 7px;
      width: 128px;
      height: 28px;

      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: $text-default;

      border-radius: 4px;
      background-color: $action-secondary-hovered;
      user-select: none;

      i {
        margin-right: 4px;
      }

      &-name {
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
        color: $text-default;
      }
      &-delete {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        margin-bottom: 1px;
        margin-left: auto;

        .icon-cross {
          margin-right: 0;
          font-size: 12px;
          color: $text-default;
        }
      }
    }

    .files-info {
      padding-left: 5px;
      padding-right: 8px;
      height: 28px;
      width: 128px;
      display: flex;
      margin-right: 7px;
      align-items: center;
      margin-bottom: 5px;

      background-color: $action-secondary-hovered;
      border-radius: 4px;
      cursor: pointer;

      i {
        margin-right: 4px;
      }

      &-name {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: $text-default;
      }
      &-delete {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        margin-bottom: 1px;
        margin-left: auto;

        .icon-cross {
          margin-right: 0;
          font-size: 12px;
          color: $text-default;
        }
      }
    }
    .upload-more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
      padding-left: 4px;
      padding-right: 7px;
      width: 128px;
      height: 28px;

      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: $text-default;

      border-radius: 4px;
      background-color: $action-secondary-hovered;
      user-select: none;
      cursor: pointer;
      transition: all 0.2s ease;

      @media (hover: hover) {
        &:hover {
          background-color: $action-secondary-pressed;
        }
      }

      &:active {
        background-color: $action-secondary-pressed;
      }

      .icon-download {
        margin-right: 8px;
      }
    }
  }
}
</style>
